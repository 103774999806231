<template>
  <div
    class="component-barcode-scanner"
    style="width: 100vw; height: 100vh; position: absolute"
  >
    <svg
      class="dbrScanner-bg-loading"
      style="
        display: none;
        animation: 1s linear infinite dbrScanner-rotate;
        width: 40%;
        height: 40%;
        position: absolute;
        margin: auto;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        fill: #aaa;
      "
      viewBox="0 0 1792 1792"
    >
      <path
        d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z"
      />
    </svg>
    <svg
      class="dbrScanner-bg-camera"
      style="
        display: none;
        width: 40%;
        height: 40%;
        position: absolute;
        margin: auto;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        fill: #aaa;
      "
      viewBox="0 0 2048 1792"
    >
      <path
        d="M1024 672q119 0 203.5 84.5t84.5 203.5-84.5 203.5-203.5 84.5-203.5-84.5-84.5-203.5 84.5-203.5 203.5-84.5zm704-416q106 0 181 75t75 181v896q0 106-75 181t-181 75h-1408q-106 0-181-75t-75-181v-896q0-106 75-181t181-75h224l51-136q19-49 69.5-84.5t103.5-35.5h512q53 0 103.5 35.5t69.5 84.5l51 136h224zm-704 1152q185 0 316.5-131.5t131.5-316.5-131.5-316.5-316.5-131.5-316.5 131.5-131.5 316.5 131.5 316.5 316.5 131.5z"
      />
    </svg>
    <video
      class="dbrScanner-video"
      playsinline="true"
      style="width: 100%; height: 100%; position: absolute; left: 0; top: 0"
    ></video>
    <canvas
      class="dbrScanner-cvs-drawarea"
      style="width: 100%; height: 100%; position: absolute; left: 0; top: 0"
    ></canvas>
    <div
      class="dbrScanner-cvs-scanarea"
      style="width: 100%; height: 100%; position: absolute; left: 0; top: 0"
    >
      <div
        class="dbrScanner-scanlight"
        style="
          display: none;
          width: 100%;
          height: 3%;
          position: absolute;
          animation: 3s infinite dbrScanner-scanlight;
          border-radius: 50%;
          box-shadow: 0px 0px 2vw 1px #00e5ff;
          background: #fff;
          user-select: none;
        "
      ></div>
    </div>

    <button
      type="button"
      class="btn btn-secondary"
      @click="closed_scan"
      style="position: absolute; right: 0; top: 0"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-x-circle"
        viewBox="0 0 16 16"
      >
        <path
          d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
        ></path>
        <path
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
        ></path>
      </svg>
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      @click="torch_toggle"
      v-if="cameraCapacity"
      style="position: absolute; left: 0; top: 0"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-lightning"
        viewBox="0 0 16 16"
      >
        <path
          d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5zM6.374 1 4.168 8.5H7.5a.5.5 0 0 1 .478.647L6.78 13.04 11.478 7H8a.5.5 0 0 1-.474-.658L9.306 1H6.374z"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import DBR from "../dbr";
export default {
  data() {
    return {
      bDestroyed: false,
      pScanner: null,
      torchState: false,
      cameraCapacity: false,
    };
  },
  async mounted() {
    try {
      let scanner = await (this.pScanner =
        this.pScanner || DBR.BarcodeScanner.createInstance());
      if (this.bDestroyed) {
        scanner.destroy();
        return;
      }
      scanner.setUIElement(this.$el);
      scanner.onUnduplicatedRead = (txt, result) => {
        this.$emit("appendMessage", {
          format: result.barcodeFormatString,
          text: result.barcodeText,
          type: "result",
        });
        if (result.barcodeText.indexOf("Attention(exceptionCode") !== -1) {
          this.$emit("appendMessage", {
            msg: result.exception.message,
            type: "error",
          });
        }
      };
      await scanner.updateRuntimeSettings("speed");
      //await barcodeScanner.updateRuntimeSettings("balance"); //alternative
      //await barcodeScanner.updateRuntimeSettings("coverage"); //alternative
      let settings = await scanner.getRuntimeSettings();
      settings.barcodeFormatIds = DBR.EnumBarcodeFormat.BF_QR_CODE;
      await scanner.updateRuntimeSettings(settings);
      await scanner.open();
      let cameraallCapacity = await scanner.getCapabilities();
      if (cameraallCapacity.torch) {
        this.cameraCapacity = true;
      }
    } catch (ex) {
      this.$emit("appendMessage", { msg: ex.message, type: "error" });
      console.error(ex);
    }
  },
  async beforeDestroy() {
    // this.bDestroyed = true;
    // if (this.pScanner) {
    //   (await this.pScanner).destroy();
    // }
  },
  methods: {
    async hide_Myscan() {
      let scanner = await this.pScanner;
      scanner.close();
      // scanner.destroy();
      return;
    },
    closed_scan() {
      this.hide_Myscan().then(
        this.$emit("hideScanner"),
        this.$emit("updateTotal")
      );
    },
    async torch_action() {
      this.torchState = !this.torchState;
      if (this.torchState) {
        let scanner = await this.pScanner;
        scanner.turnOnTorch();
      } else {
        let scanner = await this.pScanner;
        scanner.turnOffTorch();
      }
    },
    torch_toggle() {
      this.torch_action();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.component-barcode-scanner {
  width: 100%;
  height: 100%;
  position: relative;
  resize: both;
}
.dbrScanner-bg-loading {
  animation: 1s linear infinite dbrScanner-rotate;
  width: 40%;
  height: 40%;
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  fill: #aaa;
}
.dbrScanner-bg-camera {
  width: 40%;
  height: 40%;
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  fill: #aaa;
}
.dbrScanner-video {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.dbrScanner-cvs-drawarea {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.dbrScanner-cvs-scanarea {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.dbrScanner-scanlight {
  width: 100%;
  height: 3%;
  position: absolute;
  animation: 3s infinite dbrScanner-scanlight;
  border-radius: 50%;
  box-shadow: 0px 0px 2vw 1px #00e5ff;
  background: #fff;
}
.dbrScanner-sel-camera {
  margin: 0 auto;
  position: absolute;
  left: 0;
  top: 0;
}
.dbrScanner-sel-resolution {
  position: absolute;
  left: 0;
  top: 20px;
}
@keyframes dbrScanner-rotate {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes dbrScanner-scanlight {
  from {
    top: 0;
  }
  to {
    top: 97%;
  }
}
</style>